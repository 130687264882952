import React from "react";
import SignON from "./../../images/GettyImages-512138283.jpg";

function CloudCommunication() {
  return (
    <div>
      <div className="max-w-7xl m-auto md:pt-10 pt-6 md:pb-20 pb-10 px-8 ">
        <div className="max-w-4xl m-auto pt-12">
          <div className="bg-black relative text-white grid md:grid-cols-3 grid-cols-1 lg:gap-10 gap-5 md:px-12 px-8 py-28">
            <div>
              <img
                src={SignON}
                alt=""
                className="lg:w-2/5 w-full lg:absolute md:-left-16 top-16"
              />
            </div>
            <div className="col-span-2">
              <p className=" text-xs font-normal">CLOUD COMMUNICATIONS</p>
              <h1 className="md:text-3xl text-2xl font-black mb-5">
                Business Cloud Communications: The Cornerstone of Digital
                Transformation
              </h1>
              <p className="md:text-lg text-base font-normal mb-8">
                A Frost & Sullivan whitepaper
              </p>
              <div className="mb-3">
                <a
                  href="/"
                  className="font-semibold tracking-wide border-b-2 border-white"
                >
                  LEARN MORE
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CloudCommunication;
